<template>
  <div id="page-member-editor">
    <MemberEditorCompact
      v-if="member_id"
      :member_id="member_id"
      @created="member_created"
      @updated="member_updated"
      @deleted="member_deleted"
    />
  </div>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

// Edit component
import MemberEditorCompact from '@/view/components/member_editor_v2/MemberEditorCompact.vue';


export default {
  name: 'member-editor-page',
  mixins: [ toasts ],
  components: {
    MemberEditorCompact
  },
  beforeRouteUpdate(to, from, next) {
    this.member_id = to.params.member_id;
    next()
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings'])
  },
  mounted() {
    //this.example = this.examples[0];
    // get member id from route
    
    this.member_id = this.$route.params.member_id;
  },
  data() {
    return {
      member_id: null
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {
    member_created(member) {},
    member_updated(member) {},
    member_deleted(member_id) {},
  }
};
</script>
